<template>
  <v-autocomplete
    v-model="distributorId"
    :placeholder="$t('common.list.selectDistributor')"
    :items="distributors"
    item-value="id"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
    <template v-slot:selection="{ item }">
      {{ item.nicename }}
    </template>

    <template v-slot:item="{ item }">
      {{ item.nicename }}
    </template>
  </v-autocomplete>
</template>
<script>
import { useDistributor } from '@/composables'
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { distributors } = useDistributor()

    const distributorId = ref(props.filters.distributor_id)

    watch(distributorId, () => emit('changed', {
      ...props.filters,
      distributor_id: distributorId.value,
    }))

    return {
      distributors,
      distributorId,
    }
  },
}
</script>
