import { computed, ref } from '@vue/composition-api'
import { countryService } from '../services'
import commonStore from '../store'

export default () => {
  const countries = ref(commonStore.state.countries)

  const countryOptions = computed(() =>
    countries.value.map(country => ({
      label: `${country.cca3_code} - ${country.name.en}${country.base ? ' (BASE)' : ''}`,
      value: country.id,
    })),
  )

  const findCountry = id => countries.value.find(country => country.id === id)

  const baseCountry = computed(() => countries.value.find(country => country.base))

  const fetchCountries = query =>
    countryService.index(query).then(({ records }) => {
      countries.value = records

      if (query.take >= 999) {
        commonStore.state.countries = records
      }

      return countries.value
    })

  if (!countries.value.length) {
    fetchCountries({ take: 999 })
  }

  return {
    countries,
    countryOptions,
    baseCountry,

    findCountry,
    fetchCountries,
  }
}
